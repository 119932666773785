.section {
  position: relative;
  z-index: 1;
  padding-bottom: 30px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 180px;
    background-color: $primary;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &__title {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    padding: 30px 0;
    margin-bottom: 0;
    color: $white;

    &::before,
    &::after {
      content: '';
      display: block;
      flex: 1;
      margin-right: 20px;
      height: 6px;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      opacity: 0.8;
    }

    &::after {
      margin-right: 0;
      margin-left: 20px;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 20px;
    }
  }
}

.section-2 {
  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $primary;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 176px;
      height: 5px;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      content: '';
      display: block;
      width: 14px;
      height: 15px;
      background-color: $primary;
      border-radius: 2px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 14px 0 0 0 $white, -14px 0 0 0 $white;
    }
  }

  &__info {
    align-items: center;
    margin-bottom: 16px;
    margin-top: 20px;
  }


  &__time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 67px;
    height: 60px;
    background-color: $primary;
    color: $white;
    line-height: 1;
    margin-right: 20px;
  }

  &__day {
    font-size: 20px;
    font-weight: 700;
  }

  &__date {
    font-size: 14px;
    margin-top: 6px;
  }

  &__sapo {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 20px;
  }

  &__title-2 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 0;
  }
}

.section-3 {
  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary;
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 12px;

    &::after {
      content: '';
      display: block;
      width: 80px;
      height: 3px;
      background-color: $secondary;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.recruitment-detail {
  border-bottom: 1px solid $border-color;
  padding-bottom: 20px;
  margin-bottom: 20px;

  h2 {
    border-left: 10px solid $secondary;
    background-color: #eee;
    padding: 16px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 700;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}

.aside {
  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px 20px;
    background-color: $primary;
    margin-bottom: 0;
    color: $white;
  }

  @media (max-width: 767px) {
    &__title {
      padding: 12px 20px;
      font-size: 16px;
    }
  }
}
