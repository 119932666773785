.footer {
  background-color: #E9E9E9;
  padding: 30px 0 0;

  &__box {
    height: 100%;
    align-items: center;
    padding: 16px 10px 16px 16px;
    border: 1px solid #ccc;
    font-size: 14px;
    font-weight: 700;

    &,
    a {
      color: $secondary;
    }
  }

  &__icon {
    display: flex;
    width: 24px;
    margin-right: 16px;
    justify-content: center;
    align-items: center;
  }

  &__end {
    background-color: #4A4A4A;
    padding: 8px 0;
  }

  &__end-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__copyright {
    color: $white;
  }

  @media (max-width: 991px) {
    &__end-inner {
      flex-direction: column;
      text-align: center;
    }

    &__copyright {
      margin-bottom: 8px;
    }
  }
}

.social {
  display: flex;
  align-items: center;

  &__item {
    display: inline-flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgba(#fff, 0.3);
    color: $white;

    &:not(:last-child) {
      margin-right: 4px;
    }

    &:hover {
      color: $white;
      background-color: $primary;
      text-decoration: none;
    }
  }
}
