$times: TimesNewRoman,
"Times New Roman",
Times,
Baskerville,
Georgia,
serif;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$opensans: 'Open Sans',
sans-serif;
