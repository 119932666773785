.header {
  position: relative;
  z-index: 100;

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__elements {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__btn {
    display: inline-flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    position: relative;
    color: $gray-800;
    font-size: 20px;

    &+& {
      margin-left: 4px;
    }

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    span {
      display: inline-flex;
      width: 15px;
      height: 15px;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      border-radius: 50%;
      font-size: 11px;
      font-weight: 700;
      text-align: center;
      color: $white;
      position: absolute;
      top: 0;
      right: 0;
    }

    img {
      max-height: 16px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (min-width: 1200px) {
    &__btn {
      display: none;
    }
  }
}

.logo {
  display: inline-flex;
  margin: 10px 0;

  img {
    height: 30px;
  }

  @media (min-width: 1200px) {
    margin: 30px 0;

    img {
      height: 45px;
    }
  }
}

.lang {
  display: flex;

  &__item {
    display: inline-flex;

    img {
      height: 16px;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
