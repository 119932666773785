.breadcrumb {
  &-item {

    &,
    &+&::before,
    &.active,
    &.active::before,
    a,
    a:hover {
      color: $gray-800;
    }
  }

  &--white .breadcrumb-item,
  &--white .breadcrumb-item+.breadcrumb-item::before,
  &--white .breadcrumb-item+.breadcrumb-item.active,
  &--white .breadcrumb-item+.breadcrumb-item.active::before,
  &--white .breadcrumb-item a,
  &--white .breadcrumb-item a:hover {
    color: $white;
  }

  @media (min-width: 1200px) {
    &-item {

      &,
      &+&::before,
      &.active,
      &.active::before,
      a,
      a:hover {
        font-size: 18px;
      }
    }
  }
}
