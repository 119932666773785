.project {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__frame {
    @include frame('rect', 75%);
    @include frame('zoom');
    flex-grow: 1;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;
    margin-bottom: 0;
    border-bottom: 1px solid $border-color;
    text-transform: uppercase;
    color: $secondary;

    a {
      display: block;
      @include max-line(1);
    }
  }
}

.partner {
  @include frame('rect', 60%);
  border: 1px solid $border-color;
}

.news {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__frame {
    @include frame('rect', 56.25%);
    @include frame('zoom');

    &:hover {
      text-decoration: none;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: $secondary;
    margin: 10px 0;
  }

  &__desc {
    @include max-line(4);
    margin-top: auto;
    margin-bottom: 15px;
  }

  &__link {
    @include btn('fill');
    display: block;
    border-left: 4px solid $primary;
    padding: 4px 0 4px 16px;
    color: $gray-800;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &__time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 67px;
    height: 60px;
    background-color: $primary;
    color: $white;
    line-height: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
  }

  &__day {
    font-size: 20px;
    font-weight: 700;
  }

  &__date {
    margin-top: 6px;
  }
}

.capacity {
  &__wrapper {
    display: block;
    max-width: 165px;
    border-radius: 50%;
    border: 1px solid $border-color;
    padding: 16px;
    transition: .3s;
    margin: 0 auto 8px;

    &:hover {
      background-color: $secondary;
      border-color: $secondary;
    }
  }

  &__frame {
    @include frame('circle');
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;

    a:hover {
      color: $secondary;
    }
  }
}

.m-card {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__frame {
    @include frame('rect', 56.25%);
    @include frame('zoom');
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 35px;
    border: 1px solid $border-color;
    border-top: 0;
    background-color: $white;
    position: relative;
    flex-grow: 1;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $secondary;
    margin-bottom: 8px;
  }

  &__desc {
    @include max-line(4);
    text-align: center;
    margin-top: auto;
  }

  &__btn {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
