.pagination {
  margin-bottom: 0;

  .page-item {
    &:not(:last-child) {
      margin-right: 6px;
    }
  }

  .page-link {
    color: $gray-800;
    padding: 6px 12px;
    border-radius: 0 !important;
    border: 0 !important;
    background-color: #EEEEEE;
  }

  .page-item.disabled .page-link {
    background-color: #EEEEEE;
  }

  @media (max-width: 767px) {
    .page-link {
      padding: 4px 10px;
    }
  }
}
