.banner {
  position: relative;
  z-index: 1;
  height: 230px;

  &__bg {
    @include img('bg');
  }

  &__breadcrumb {
    padding-top: 140px;
  }

  @media (max-width: 767px) {
    height: 100px;

    &__breadcrumb {
      padding-top: 40px;
    }
  }
}

.as-menu {
  list-style: none;
  padding-left: 50px;
  margin-bottom: 0;
  border: 1px solid $border-color;
  border-top: 0;
  overflow: hidden;

  &__link {
    display: block;
    font-size: 14px;
    color: $secondary;
    text-transform: uppercase;
    position: relative;
    padding: 10px 0;
    line-height: 20px;

    &:hover {
      color: $secondary;
      text-decoration: none;
    }

    &.active {
      color: $secondary;
      font-weight: 700;
      text-decoration: none;
    }

    img {
      position: absolute;
      top: 20px;
      left: -25px;
      transform: translate(-50%, -50%);
    }
  }

  &__item:not(:last-child) &__link:last-child {
    border-bottom: 1px solid $border-color;
  }

  &__sub {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    border-top: 1px solid $border-color;
    padding: 4px 0;
  }

  &__sub-item {
    position: relative;
    padding: 6px 6px 6px 20px;

    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: #333333;
      position: absolute;
      top: 12px;
      right: 100%;
      transition: .3s;
    }

    &:hover::before {
      width: 60px;
      background-color: $primary;
    }
  }

  &__sub-link {
    color: $secondary;

    &:hover {
      color: $secondary;
      text-decoration: none;
    }

    &.active {
      font-weight: 700;
    }
  }
}

.partners {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 575px) {
    grid-gap: 16px;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: row dense;
  grid-gap: 30px;
  margin-bottom: 30px;

  &__item {
    &:nth-child(1) {
      grid-row: span 2;
    }

    &:nth-child(3) {
      grid-column: span 2;

      .project__frame {
        max-height: 191px;
      }
    }
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;

    &__item {
      &:nth-child(1) {
        grid-row: span 1;
      }

      &:nth-child(3) {
        grid-column: span 1;

        .project__frame {
          max-height: unset;
        }
      }
    }
  }

  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
}

.news-bar {
  position: relative;
  z-index: 10;
  color: $white;
  font-size: 24px;

  &__wrapper {
    width: 100%;
    background-color: rgba(#000, 0.3);
    padding: 12px 0;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__inner {
    align-items: center;
  }

  &__label {
    text-transform: uppercase;
    color: $white;
    padding-right: 30px;
    margin-right: 20px;
    position: relative;

    &::after {
      content: '';
      border-width: 18px 10px;
      border-style: solid;
      border-color: transparent transparent transparent $primary;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
    }
  }

  &__body {
    display: flex;
    align-items: center;
  }

  a,
  a:hover {
    color: $white;
    display: inline-block;
    margin-right: 20px;
  }

  @media (max-width: 991px) {
    font-size: 20px;
  }

  @media (max-width: 767px) {
    font-size: 14px;

    &__inner {
      flex-direction: column;
      align-items: stretch;
    }

    &__label {
      font-size: 14px;
      font-weight: 700;
      align-self: start;
      padding-right: 20px;

      &::after {
        border-width: 8px 8px;
      }
    }
  }
}

.home-video-content {
  margin-top: 110px;

  @media (max-width: 991px) {
    margin-top: 0;
  }
}

.home-video-title {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;
}
