.banner-slider {
  .swiper-slide {
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      background: linear-gradient(to right, rgba(#000, 0.8), transparent);
    }
  }

  &__frame {
    @include frame('rect', 56.25%);
    max-height: calc(100vh - 105px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    padding: 20px 150px;
  }

  &__title {
    font-size: 66px;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    text-shadow: 0 5px 20px rgba(#000, 0.8);
    transform-origin: left;
    transform: translateY(-100px) scale(0.6);
    opacity: 0;
    transition: 1.5s 0.6s;
    max-width: 600px;

    span {
      position: relative;
    }
  }

  .swiper-slide-active &__title {
    transform: translateY(0) scale(1);
    opacity: 1;
  }

  &__desc {
    font-size: 24px;
    color: #fff;
    max-width: 600px;
    margin: 16px 0 0;
    text-shadow: 0 5px 20px rgba(#000, 0.8);

    span {
      position: relative;
    }
  }

  @media (max-width: 1199px) {
    &__content {
      padding: 20px 80px;
    }

    &__title {
      font-size: 40px;
    }

    &__desc {
      font-size: 18px;
    }
  }

  @media (max-width: 767px) {
    &__content {
      position: relative;
      height: auto;
      padding: 40px 30px 100px;
    }

    &__title {
      font-size: 30px;
    }

    &__desc {
      font-size: 14px;
      margin-top: 8px;
    }

    &__frame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
}
