.btn {
  &.-default {
    border-color: $border-color;
    background-color: transparent;

    &:hover {
      border-color: $primary;
      color: $primary;
    }
  }

  &.-rounded {
    border-radius: 18px;
  }

  &.-primary {
    @extend .btn-primary;
  }

  &.-primary-o {
    @extend .btn-outline-primary;
  }

  &.-danger {
    @extend .btn-danger;
  }

  &.-danger-o {
    @extend .btn-outline-danger;
  }

  &.-shine {
    @include btn("shine");
  }

  &.-fill {
    @include btn("fill");
  }

  &.fill.-primary {
    &::before {
      background-color: $white;
    }

    &:hover {
      color: $primary;
    }
  }

  &.-fill.-danger {
    &::before {
      background-color: $white;
    }

    &:hover {
      color: $danger;
    }
  }

  &.-fill.-primary-o {
    &::before {
      background-color: $primary;
    }

    &:hover {
      color: $white;
      background-color: $white;
    }
  }

  &.-fill.-danger-o {
    &::before {
      background-color: $danger;
    }

    &:hover {
      color: $white;
      background-color: $white;
    }
  }

  &.-neon-orange {
    @include btn-neon("orange");
  }

  &.-neon-green {
    @include btn-neon("green");
  }

  &.-neon-purple {
    @include btn-neon("purple");
  }

  &.-neon-blue {
    @include btn-neon("blue");
  }

  &.-neon-dark {
    @include btn-neon("dark");
  }
}

$rounded-list: 8 10 18 20 22 24 25 26 28 30;

@each $item in $font-size-list {
  .rounded-#{$item} {
    border-radius: #{$item}px;
  }
}

.button {
  @include btn('shine');

  display: inline-block;
  background: $primary;
  color: $white;
  font-size: 14px;
  color: $white;
  text-transform: uppercase;
  font-weight: 400;
  border: 0;
  border-radius: 4px;
  width: 225px;
  text-align: center;
  padding: 10px 16px;

  &:hover {
    color: $white;
  }
}
